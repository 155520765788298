import Subscriptions from "pages/subscriptions";
import Order from "pages/Order";
import PaymentVerification from "pages/PaymentVerification";
import IOS from "pages/IOS";
import { createBrowserRouter } from "react-router-dom";
import { PUBLIC_PAGES } from "./pagePath";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Subscriptions />,
  },
  {
    path: PUBLIC_PAGES.SUBSCRIPTION,
    element: <Subscriptions />,
  },
  {
    path: PUBLIC_PAGES.ORDER,
    element: <Order />,
  },
  {
    path: PUBLIC_PAGES.PAYMENT_VERIFICATION,
    element: <PaymentVerification />,
  },
  {
    path: PUBLIC_PAGES.IOS,
    element: <IOS />,
  },
  {
    path: PUBLIC_PAGES.IOS_HOME,
    element: <IOS />,
  },
  {
    path: PUBLIC_PAGES.IOS_WHATSAPP,
    element: <IOS />,
  },
  {
    path:PUBLIC_PAGES.ANDROID_HOME,
    element: <IOS />,
  },
]);
