import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  ModalCloseButton,
  ModalBody,
  Heading,
  Box,
} from "@chakra-ui/react";
import diamond from "assets/diamond.png";
import { EVENTS, Mixpanel } from "lib/mixpanel";
import { useNavigate } from "react-router-dom";
import { ENV } from "utils/constants";
import { formatCurrency } from "utils/helpers";
import useGlobalStore from "zStore";
import {
  DeviceType,
  DurationValue,
  getSubscriptionDetails,
  PlanType,
} from "./SubCard";

type GoldModalProps = {
  isOpen: boolean;
  onClose: () => void;
  allSubs: Array<PlanType>;
  durationType: DurationValue;
  device: DeviceType;
  businessId: string | null;
};

const GoldModal = (props: GoldModalProps) => {
  const { isOpen, onClose, allSubs, durationType, device, businessId } = props;
  const [setSub] = useGlobalStore((state) => [state.setSubscription]);
  const navigate = useNavigate();

  const goldSub = allSubs.find((value: any) => value.header.name === "Gold");
  const silverSub = allSubs.find(
    (value: any) => value.header.name === "Silver"
  );
  const isIos = device === "ios";

  const goldPrice = goldSub
    ? isIos
      ? goldSub.price.duration[durationType].ios
      : goldSub.price.duration[durationType].cost
    : 0; // Default value if goldSub is undefined

  const silverPrice = silverSub
    ? isIos
      ? silverSub.price.duration[durationType].ios
      : silverSub.price.duration[durationType].cost
    : 0; // Default value if silverSub is undefined

  const priceDiff = silverSub ? +goldPrice - +silverPrice : 0;

  // Use a default currency if silverSub is undefined
  const currency = silverSub ? silverSub.price.currency : "USD";

  const price = `${formatCurrency(+priceDiff, currency)}`;

  const handleSelection = (selectedPlan: string) => {
    if (selectedPlan === "Gold") {
      Mixpanel.track(EVENTS.GOLD_CLICKED);
    } else {
      Mixpanel.track(EVENTS.SILVER_CLICKED);
    }
    const plan = selectedPlan === "Gold" ? goldSub : silverSub;
    // Check if plan is defined before proceeding
    if (!plan) return;

    let iosExternalPlanId =
      plan.price.duration[durationType].iosExternalPlanId || "";
    const iosPlanId = plan.price.duration[durationType].iosPlanId;
    const planId = plan.price.duration[durationType].id;

    if (device === "ios") {
      if (process.env.REACT_APP_CLIENT_ENV_QA === ENV.DEV) {
        // Check if the value is a production value
        if (iosExternalPlanId.includes(".OZE.")) {
          iosExternalPlanId = iosExternalPlanId.replace(
            ".OZE.",
            ".testflightqa."
          );
        }
      }
      const url = `/ios?planId=${iosPlanId}&externalPlanId=${iosExternalPlanId}`;
      const iosPage = url;
      return (window.location.href = iosPage);
    }
    const { duration, durationWord } = getSubscriptionDetails(durationType);
    const currentSub = {
      planName: plan.header.name,
      image: plan.header.logo,
      duration,
      durationWord,
      date: new Date(),
      currency: plan.price.currency,
      amount: selectedPlan === "Gold" ? goldPrice : silverPrice,
      id: planId,
      businessId,
    };
    setSub({
      ...currentSub,
    });
    const location = window.location.href;
    localStorage.setItem("current-location", location);
    navigate("/order");
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
        <ModalOverlay />
        <ModalContent p="40px 27px">
          <ModalCloseButton />
          <Image
            w="70px"
            h="78px"
            mt="4"
            mx="auto"
            objectFit="cover"
            src={diamond}
            alt="success Logo"
          />
          <Heading
            mt="32px"
            color="#1246EA"
            fontWeight={500}
            fontSize="20px"
            textAlign="center"
            lineHeight="155%" /* 31px */
            letterSpacing="-0.048px"
          >
            For only {price} more, unlock the power of Gold to get:
          </Heading>
          <ModalBody fontSize="14px">
            <ul className="centered-list">
              <li>Unlimited invoices and receipts,</li>
              <li>Unlimited products and services,</li>
              <li>Unlimited products on Oze online shop,</li>
              <li>Access to Oze on your laptop, and much more!</li>
            </ul>
          </ModalBody>
          <Box>
            <Button
              w="100%"
              mt="32px"
              bg="blue"
              h="48px"
              onClick={() => handleSelection("Gold")}
            >
              Yes, choose Gold now!
            </Button>
            <Button
              w="100%"
              mt="17px"
              color="blue"
              h="48px"
              textDecor="underline"
              variant="link"
              onClick={() => handleSelection("Silver")}
            >
              No thanks, stay on Silver
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GoldModal;
