import { Box } from "@chakra-ui/react";
import BaseModal from "components/Subscription/BaseModal";
import { LoadingBody } from "./PaymentVerification";

const IOS = () => {
  return (
    <Box bg="rgba(0, 0, 0, 0.6)" minH="100vh">
      <BaseModal isOpen={true} onClose={() => {}}>
        <LoadingBody subText="Redirecting to App" />
      </BaseModal>
    </Box>
  );
};

export default IOS;
