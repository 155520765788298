import mixpanel, { Dict } from "mixpanel-browser";
import APP_CONFIG from "./config";
// import { ENV } from "@/utils/constants";

export enum ENV {
  PROD = "OZE_PROD",
  DEV = "OZE_DEV",
  PRODUCTION = "production",
  DEVELOPMENT = "development",
}

mixpanel.init(APP_CONFIG.MIXPANEL_KEY, {
  debug: APP_CONFIG.CLIENT_ENV === ENV.DEV,
  autotrack: true,
});

export const Mixpanel = {
  track: (name: string, props?: Dict) => {
    mixpanel.track(name, { ...props });
  },
};

export const EVENTS = {
  PROMPT_VIEWED: "O2_Gold_Upsell_Prompt_Seen",
  GOLD_CLICKED: "O2_Gold_Upsell_Select_Gold_CTA_Clicked",
  SILVER_CLICKED: "O2_Gold_Upsell_Stay_Silver_CTA_Clicked",
  PROMO_APPLY_BTN_CLICKED: "O2_Redeem_Discount_ApplyBtn_Clicked",
  PROMO_DISCOUNT_SUCCESS_SEEN: "O2_Redeem_Discount_Success_Seen",
  PROMO_DISCOUNT_ERROR_SEEN: "O2_Redeem_Discount_Error_Seen",
  PROMO_DISCOUNT_REMOVE_BTN_CLICKED: "O2_Redeem_Discount_Remove_Clicked",
};
