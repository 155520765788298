import { extendTheme } from "@chakra-ui/react";
import { ButtonStyles as Button } from "./components/buttonStyles";

// custom themes in chakra UI
// https://chakra-ui.com/docs/theming/customize-theme
// https://www.easyreact.com/articles/chakra-ui-customisations

const myTheme = extendTheme({
  fonts: {
    heading: "DM Sans",
    body: "DM Sans",
  },
  colors: {
    primary: "#1246EA",
    secondary: {
      100: "#E7ECFD",
      400: "#4D4D4D",
    },
    critical: {
      100: "#C71313",
    },
    header: {
      top__bg: "#0A0C29",
      bottom__bg: "#E7ECFD",
    },
  },
  components: {
    Button, // Has to match to the name of the component
  },
});

export default myTheme;
