import { ButtonProps, Button } from "@chakra-ui/react";
import React from "react";

interface HeaderButtonsType extends ButtonProps {
  isActive: boolean;
}

const HeaderButtons = ({ children, isActive, ...rest }: HeaderButtonsType) => {
  return (
    <Button
      w="100%"
      variant="unstyled"
      fontSize="12px"
      fontWeight={isActive ? 700 : 400}
      bg={isActive ? "#fff" : ""}
      color={isActive ? "#1246EA" : ""}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default HeaderButtons;
