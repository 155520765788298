import { Box, Flex } from "@chakra-ui/layout";
import { Heading, Button, useDisclosure } from "@chakra-ui/react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import Slider from "react-slick";
import SubCard, { PlanType } from "components/Subscription/SubCard";
import SliderArrow from "components/Subscription/SliderArrow";
import HeaderButtons from "components/Subscription/HeaderButtons";
import { useLocation } from "react-router-dom";
import { PUBLIC_PAGES } from "routes/pagePath";
// import bronze from "assets/plans/bronze.png";

import { useState, useEffect } from "react";

import { useSubscriptionData } from "components/Subscription/useSubscriptionData";
import { processSubscriptionData } from "components/Subscription/subscriptionHelper";
import Loader from "components/Loader";
import GoldModal from "components/Subscription/GoldModal";

const Subscriptions = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const country = queryParams.get("country");
  const deviceParam = queryParams.get("device");
  const businessId = queryParams.get("businessId");
  const {
    isLoading,
    isError,
    data,
    durationType,
    setDurationType,
    device,
    subscriptionType,
    whatsaAppHandle,
  } = useSubscriptionData(country, deviceParam);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [allSubs, setAllSubs] = useState<Array<PlanType>>([]);

  useEffect(() => {
    if (!isLoading && data) {
      const processedData = processSubscriptionData(data, device);
      setAllSubs(processedData);
    }
  }, [data, isLoading, device]);

  const backToApp = () => {
    let backToApp = PUBLIC_PAGES.ANDROID_HOME;
    if (device === "ios") {
      backToApp = PUBLIC_PAGES.IOS_HOME;
      return (window.location.href = backToApp);
    }
    window.close();
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    afterChange: (current: number) => {
      setCurrentSlide(current);
    },
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow:
      currentSlide === allSubs.length - 1 ? undefined : <SliderArrow />,
    prevArrow: currentSlide === 0 ? undefined : <SliderArrow type="prev" />,
  };
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <div>An Error Occured...</div>;
  }
  return (
    <Box bg="#718ce6">
      <Box bg="#0E37B9" minH="100vh" color="#fff" maxW="500px" mx="auto">
        <Flex
          pt="2rem"
          px="23px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button variant="none" onClick={backToApp}>
            <IoIosArrowRoundBack size="30px" />
          </Button>
          <Button
            variant="unstyled"
            bg="#fff"
            color="#161A5C"
            borderRadius="22px"
            p="0.5rem 1rem"
            textAlign="center"
            alignItems="center"
            display="flex"
            ml="24px"
            fontSize="12px"
            fontWeight="500"
            onClick={() => {
              if (device === "ios") {
                const backToApp = PUBLIC_PAGES.IOS_WHATSAPP;
                return (window.location.href = backToApp);
              }

              whatsaAppHandle();
            }}
            leftIcon={<BsFillQuestionCircleFill />}
          >
            Get Help
          </Button>
        </Flex>
        <Box px="23px" mt="4">
          <Heading as="h1" fontSize="14px">
            Choose a Payment Plan
          </Heading>
          <Flex mt="8px" bg="rgba(255,255,255,0.1)">
            {subscriptionType.map((subscription) => {
              return (
                <HeaderButtons
                  key={subscription.id}
                  isActive={durationType === subscription.durationType}
                  onClick={() => {
                    setDurationType(subscription.durationType);
                  }}
                >
                  {subscription.name}
                </HeaderButtons>
              );
            })}
          </Flex>
        </Box>
        <Slider {...sliderSettings}>
          {allSubs.map((sub) => (
            <SubCard
              key={sub.id}
              plan={sub}
              durationType={durationType}
              deviceType={device}
              businessId={businessId}
              openGoldModal={onOpen}
            />
          ))}
        </Slider>
        {isOpen ? (
          <GoldModal
            onClose={onClose}
            allSubs={allSubs}
            durationType={durationType}
            device={device}
            isOpen={isOpen}
            businessId={businessId}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default Subscriptions;
