import axios, { AxiosInstance } from "axios";
export * from "./request";


const stagingBase ="https://api-dev-v6.oze.guru"
const stagingAuth ="Basic QkFfb3plYXBwOnVWNmVhcjc5S1N0N1hQTCE5"

export const baseURL = process.env.REACT_APP_ENDPOINT_URL! || stagingBase;


const basicAuth = process.env.REACT_APP_AUTH ||stagingAuth;
const clientRef = process.env.REACT_APP_CLIENT_REF || stagingAuth;
const clientXRef = process.env.REACT_APP_X_REQ_REF || stagingAuth;

export const headers = {
  Accept: "application/json, text/plain, */*",
  "Content-Type": "application/json",
  Authorization: basicAuth,
  "X-Client-Ref": clientRef,
  "X-Request-Ref": clientXRef,
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
  headers
});

export default axiosInstance;
