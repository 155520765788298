import { useQuery, useMutation, QueryKey, UseQueryOptions } from "@tanstack/react-query";
import axiosInstance from ".";
import { toast } from "react-hot-toast";

type QueryOptions = Omit<UseQueryOptions<any, unknown, any, QueryKey>, "queryKey" | "queryFn">;

export const useQueryWrapper = (key: QueryKey, url: string, options?: QueryOptions) => {
  const getAPICall = async () => {
    const { data } = await axiosInstance.get(url);
    return data;
  };
  return useQuery(key, getAPICall, options);
};

export const getRequest = async (url: string) => {
  const response = await axiosInstance.get(url);
  return response?.data;
};

export const postRequest = async ({ url, data }: any) => {
  const response = await axiosInstance.post(url, data);
  return response?.data || response;
};

export const putRequest = async ({ url, data }: any) => {
  const response = await axiosInstance.put(url, data);
  return response?.data || response;
};
export const patchRequest = async ({ url, data }: any) => {
  const response = await axiosInstance.patch(url, data);
  return response?.data || response;
};

export const deleteRequest = async ({ url, data }: any) => {
  const config = { data };
  const response = await axiosInstance.delete(url, config);
  return response?.data || response;
};

// TODO: write JSDocs for this function
export const useMutationWrapper = (makeAPICall: any, onSuccess?: any, onError?: any): any => {
  return useMutation(makeAPICall, {
    onSuccess: (res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    },

    onError: (error: any) => {
      const err = error as Record<any, any>;
      const message: any = err?.response?.data?.errors;
      if (Array.isArray(message)) {
        message.map((errorMsg) => toast.error(`${errorMsg ?? "An error occured"}`));
      } else {
        toast.error(`${message ?? "An error occured"}`);
      }
      if (onError) {
        onError(error);
      }
    },
  });
};

export const reactQueryConfig = {
  defaultOptions: {
    queries: {
      staleTime: 1 * 60 * 60 * 1000,
      cacheTime: 5 * 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
};
