import { Flex } from "@chakra-ui/layout";

const SliderArrow = (props: any) => {
  const { className, onClick, type } = props;
  return (
    <Flex
      display="flex !important"
      alignItems="center"
      justifyContent="center"
      borderRadius="100%"
      width={{ lg: "64px !important", base: "32px !important" }}
      height={{ lg: "64px !important", base: "32px !important" }}
      background="#FFF !important"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      className={className}
      onClick={onClick}
    >
      {type === "prev" ? (
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.05078 4.82031C0.796875 5.05469 0.796875 5.46484 1.05078 5.69922L4.80078 9.44922C5.03516 9.70312 5.44531 9.70312 5.67969 9.44922C5.93359 9.21484 5.93359 8.80469 5.67969 8.57031L2.37891 5.25L5.67969 1.94922C5.93359 1.71484 5.93359 1.30469 5.67969 1.07031C5.44531 0.816406 5.03516 0.816406 4.80078 1.07031L1.05078 4.82031Z"
            fill="#1246EA"
          />
        </svg>
      ) : (
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.92969 4.82031C8.18359 5.05469 8.18359 5.46484 7.92969 5.69922L4.17969 9.44922C3.94531 9.70312 3.53516 9.70312 3.30078 9.44922C3.04688 9.21484 3.04688 8.80469 3.30078 8.57031L6.60156 5.25L3.30078 1.94922C3.04688 1.71484 3.04688 1.30469 3.30078 1.07031C3.53516 0.816406 3.94531 0.816406 4.17969 1.07031L7.92969 4.82031Z"
            fill="#1246EA"
          />
        </svg>
      )}
    </Flex>
  );
};

export default SliderArrow;
