import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';

type BaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactElement;
  closeOnOverlayClick?: boolean;
};

const BaseModal = (props: BaseModalProps) => {
  const { isOpen, onClose, children, closeOnOverlayClick = true } = props;
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={closeOnOverlayClick} isCentered>
        <ModalOverlay />
        <ModalContent mx="24px">{children}</ModalContent>
      </Modal>
    </>
  );
};

export default BaseModal;
