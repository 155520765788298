import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import theme from "./styles/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { router } from "routes";
import { reactQueryConfig } from "services/api/apiHelper";
import  { Toaster } from 'react-hot-toast';
const queryClient = new QueryClient(reactQueryConfig);
export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
    <Toaster />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
