import { bronze, gold, platinum, silver } from "components/Subscription/constant";
import { DeviceType } from './SubCard';


export const sorter = (x: { orderId: number }, y: { orderId: number }) => {
  if (x.orderId > y.orderId) {
    return 1;
  }
  if (x.orderId < y.orderId) {
    return -1;
  }
  return 0;
};


export function combineBaseWithIOS(arr:any) {
  const baseItems = arr.filter((item: { platform: null; }) => item.platform === null);
  const iosItems = arr.filter((item: { platform: string; }) => item.platform === 'IOS');

  return baseItems.map((baseItem: any) => {
    const matchingIOS = iosItems.find((iosItem:any) => iosItem.durationType === baseItem.durationType&&iosItem.name === baseItem.name);
    if (matchingIOS) {
      return {
        ...baseItem,
        iosexternalPlanId: matchingIOS.externalPlanId,
        iosPlanId: matchingIOS.id,
        iosCurrency: matchingIOS.currency,
        iosPricingValue: matchingIOS.pricingValue,
      };
    } else {
      return baseItem;
    }
  });
}
function mergeData(combinedData: any,device:DeviceType) {
  const mergedData: any = {};

  combinedData.forEach((item: any,) => {
    let otherInfo = {};
    switch (item.name) {
      case "GOLD":
        otherInfo = gold;
        break;
      case "PLATINUM":
        otherInfo = platinum;
        break;
      case "SILVER":
        otherInfo = silver;
        break;
      default:
        otherInfo = bronze;
    }
    if (!mergedData[item.name]) {
      mergedData[item.name] = {
        ...otherInfo,
        id: item.id,
        price: {
          currency: device==="ios"?   item.iosCurrency : item.currency,
          duration: {},
        },
      };
    }
    if (mergedData[item.name].header.name === "Bronze") {
      mergedData[item.name] = {
        ...bronze,
        id: item.id,
      };
    } else {
      mergedData[item.name].price.duration[item.durationType] = {
        cost: item.pricingValue,
        id: item.id,
        save: item.discountValue ? item.discountValue : "more",
        ios: item.iosPricingValue || "",
        iosPlanId: item.iosPlanId,
        iosExternalPlanId: item.iosexternalPlanId,
      };
    }
  });

  const mergedArray = Object.keys(mergedData).map((plan) => {
    const access = mergedData[plan].access;
    const header = mergedData[plan].header;
    const price = mergedData[plan].price;
    const id = mergedData[plan].id;
    const orderId = mergedData[plan].orderId;
    const iosPlanId = mergedData[plan].iosPlanId;
    return {
      id,
      iosPlanId,
      access,
      header,
      price,
      orderId,
    };
  });

  return mergedArray.sort(sorter);
}

export function processSubscriptionData(data: any,device:DeviceType) {
  const combinedData = combineBaseWithIOS(data.data);
  const processedData = mergeData(combinedData,device);
  return processedData;
}
