import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "services/api";
import { subRequest } from "services";
import { convertParamsToString, chatOnWhatsApp } from "utils/helpers";

export type DurationValue = "SEMI_ANNUAL" | "ANNUAL" | "MONTH";
export type DeviceType = "android" | "ios";
type SubType = {
  id: string;
  name: string;
  durationType: DurationValue;
};
export function useSubscriptionData(country: string | null, deviceParam: string | null) {
  const [durationType, setDurationType] = useState<DurationValue>("SEMI_ANNUAL");
  const [device, setDevice] = useState<DeviceType>("android");
  const [url, setUrl] = useState("");

  const subscriptionType: Array<SubType> = [
    {
      id: "firstId123",
      name: "Annually",
      durationType: "ANNUAL",
    },
    {
      id: "secondId123",
      name: "6-Months",
      durationType: "SEMI_ANNUAL",
    },
    {
      id: "thirdId123",
      name: "Monthly",
      durationType: "MONTH",
    },
  ];
  useEffect(() => {
    let subUrl = "";
    switch (country) {
      case "ng":
        subUrl = convertParamsToString(subRequest.SUB_PLAN, { country:country.toLowerCase() });
        setUrl(subUrl);
        break;
      case "gh":
        subUrl = convertParamsToString(subRequest.SUB_PLAN, { country: device === "ios" ? "US" : country.toLowerCase() });
        setUrl(subUrl);
        break;
      default:
        subUrl = convertParamsToString(subRequest.SUB_PLAN, { country: "US" });
        setUrl(subUrl);
        break;
    }
  }, [country, device]);

  useEffect(() => {
    if (deviceParam) {
      if (deviceParam.toLowerCase() === "ios") setDevice("ios");
    } else {
      setDevice("android");
    }
  }, [deviceParam]);

  const getAPICall = async ({ queryKey }: any) => {
    const url = queryKey[1];
    const { data } = await axiosInstance.get(url);
    return data;
  };
  const whatsaAppHandle = () => {
    const ghana = "+233545750476";
    const nigeria = "  +2349130000118";
    let whatapp = ghana;
    if (country === "ng") {
      whatapp = nigeria;
    }
    chatOnWhatsApp("Hi I need some help", whatapp);
  };
  const { isLoading, isError, data } = useQuery(["GET_SUB_API", url], getAPICall, {
    enabled: url !== "",
    staleTime: 3 * (60 * 1000), // 3 mins
  });

  return { isLoading, isError, data, durationType, setDurationType, device, subscriptionType, whatsaAppHandle } as const;
}
