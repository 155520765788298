import { Text, Box, Flex, Button, Input } from '@chakra-ui/react';
import usePromoCode from 'hooks/usePromoCode';
import { EVENTS, Mixpanel } from 'lib/mixpanel';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';

type Props = {
  subscriptionPlanId: string;
};

export default function PromoCodeBox({ subscriptionPlanId }: Props) {
  const {
    onSubmit,
    promoCodeError,
    promoCode,
    setPromoCode,
    setPromoCodeError,
    submittingCode,
    successMessage,
    setSuccessMessage,
    setDiscountCampaign,
  } = usePromoCode({ subscriptionPlanId });

  return (
    <Box mt="6">
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="secondary.400">Have a promo code?</Text>
        {successMessage ? (
          <Button
            onClick={() => {
              Mixpanel.track(EVENTS.PROMO_DISCOUNT_REMOVE_BTN_CLICKED);
              setPromoCode('');
              setSuccessMessage(null);
              setDiscountCampaign(null);
            }}
            px="0"
            variant="link"
            color="primary"
            textDecor="underline"
            fontSize="sm"
            fontWeight="semibold"
          >
            Remove
          </Button>
        ) : (
          <Button
            onClick={() => {
              setPromoCode('');
              setPromoCodeError('');
            }}
            px="0"
            variant="link"
            color="primary"
            textDecor="underline"
            fontSize="sm"
            fontWeight="semibold"
          >
            Dismiss
          </Button>
        )}
      </Flex>
      {successMessage ? (
        <Flex alignItems="center" gap="3" my="2">
          <Text color="primary" fontSize="lg" fontWeight="semibold">
            Code applied. Enjoy your {successMessage}% off!
          </Text>
          <Text color="primary" fontSize="xl" fontWeight="semibold">
            <IoIosCheckmarkCircleOutline />
          </Text>
        </Flex>
      ) : (
        <Flex alignItems="center" mb="1">
          <Input
            placeholder="Enter your discount code"
            focusBorderColor="#1246EA"
            borderRightRadius={0}
            borderRightColor="transparent"
            borderColor={promoCodeError ? "critical.100" : "#999"}
            h="12"
            value={promoCode}
            isDisabled={submittingCode}
            onChange={(e) => {
              if (promoCodeError) setPromoCodeError('');
              setPromoCode(e.target.value);
            }}
          />
          <Button
            w="88px"
            h="12"
            bg="#1246EA"
            fontSize="13px"
            fontWeight="400"
            color="#fff"
            borderLeftRadius={0}
            isLoading={submittingCode}
            onClick={() => onSubmit(promoCode)}
          >
            Apply
          </Button>
        </Flex>
      )}
      {promoCodeError ? (
        <Text fontSize="sm" color="critical.100">
          {promoCodeError}
        </Text>
      ) : null}
    </Box>
  );
}
