import APP_CONFIG from "lib/config";
import { ENV } from "./constants";

export const formatCurrency = (
  value: number | null | undefined,
  currency = "NGN"
): string => {

  let newCurrency = currency;
  let customDisplay = false;

  if (currency === "GHS") {
    newCurrency = "GH₵"; // Set the custom format "GH¢"
    customDisplay = true;
  }

  if (customDisplay) {
    // If customDisplay is true, prepend the custom currency symbol to the formatted number
    return newCurrency + ' ' + new Intl.NumberFormat("en-NG", {
      minimumFractionDigits: 0,
    }).format(value || 0);
  } else {
    // Otherwise, format the currency using Intl.NumberFormat
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      minimumFractionDigits: 0,
      currency: newCurrency,
      currencyDisplay: "symbol",
    }).format(value || 0);
  }
};


export const convertParamsToString = (
  url: string,
  urlVariables: Record<string, string | any>
): string => {
  let finalURL = url;

  for (const [key, value] of Object.entries(urlVariables)) {
    finalURL = finalURL.replace(`:${key}`, value);
  }

  return finalURL;
};

export const chatOnWhatsApp = (message: string, phoneNo: string) => {
  const encodedText = encodeURI(message);
  window.open(`https://wa.me/${phoneNo.trim()}?text=${encodedText}`);
};

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export const backToWebApp = () => {
  let baseURL = 'https://www.test-app.getoze.com';
  if (APP_CONFIG.CLIENT_ENV === ENV.PROD) {
    baseURL = 'https://www.app.getoze.com';
  }
  window.location.href = baseURL + '/login';
};