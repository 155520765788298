import { Box, Button, Image, Text, useBoolean } from '@chakra-ui/react';
import successImg from 'assets/success.png';
import BaseModal from './BaseModal';
import useGlobalStore from 'zStore';
import { backToWebApp } from 'utils/helpers';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function DiscountSuccessModal({ isOpen, onClose }: Props) {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <SuccessBody />
    </BaseModal>
  );
}

const SuccessBody = () => {
  const [selectedSub] = useGlobalStore((state) => [state.subscription]);

  const [isLoading, setIsLoading] = useBoolean();
  return (
    <Box p="24px 31px" textAlign="center">
      <Text fontWeight={600} fontSize="18px">
        Subscription Successful
      </Text>
      <Image mt="4" mx="auto" objectFit="cover" src={successImg} alt="success Logo" />
      <Text mt="20px" mb="28px">
        Welcome to the Oze {selectedSub?.durationWord} {selectedSub?.planName} plan. Your
        subscription was successful 🎉.
      </Text>
      <Button
        isLoading={isLoading}
        w="100%"
        h="48px"
        bg="#1246EA"
        color="#fff"
        onClick={() => {
          setIsLoading.on();
          backToWebApp();
        }}
      >
        Back to Oze
      </Button>
    </Box>
  );
};
