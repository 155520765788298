import { create } from "zustand";
import bronze from "assets/plans/bronze.png";
import { SubscriptionCampaignResponse } from "utils/types";
type selectedSubscription = {
  planName: string;
  duration: string;
  durationWord: string;
  date: Date;
  currency: any;
  amount: number;
  image: string;
  id: string;
  businessId:string;
};
interface GlobalStoreState {
  subscription: selectedSubscription;
  setSubscription: (sub: any) => void;
  duration: any;
  setDuration: (duration: any) => void;
  discountCampaign: SubscriptionCampaignResponse["data"] | null;
  setDiscountCampaign: (discountCampaign: SubscriptionCampaignResponse["data"] | null) => void;
}

const globalStore = <F extends Function>(set: F) => ({
  subscription: {
    image: bronze,
    planName: "bronze",
    duration: "unlimited",
    durationWord:"unlimited",
    date: new Date(),
    currency: "USD",
    amount: 0,
    id: "3_0r4R6C",
    businessId:"12345678"
  },
  setSubscription: (subscription: any) => {
    set({ subscription });
  },

  duration: {},
  setDuration: (duration: any) => {
    set({ duration });
  },

  discountCampaign: null,
  setDiscountCampaign: (discountCampaign: SubscriptionCampaignResponse["data"] | null) => {
    set({ discountCampaign });
  },
});

const useGlobalStore = create<GlobalStoreState>(globalStore);

export type GlobalStore = ReturnType<typeof globalStore>;

export default useGlobalStore;
