import {
  Box,
  // Button,
  Image,
  useDisclosure,
  Text,
  Progress,
  useBoolean,
  Button,
} from '@chakra-ui/react';
import BaseModal from 'components/Subscription/BaseModal';
import successImg from 'assets/success.png';
import failedImg from 'assets/failed.png';
import { useState } from 'react';
import { useQueryWrapper } from 'services/api/apiHelper';
import { backToWebApp, convertParamsToString, formatCurrency, toTitleCase } from 'utils/helpers';
import { useLocation } from 'react-router-dom';
import { subRequest } from 'services/api/request';
import { PaymentStatusType, paymentResType } from 'utils/types';
// import { PUBLIC_PAGES } from 'routes/pagePath';

// const backToApp = ()=>{
//   window.close();
// }
const PaymentVerification = () => {
  const { onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useBoolean(true);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusType>('PENDING');
  const [successRes, setSuccessRes] = useState({
    amount: 0,
    currency: 'NGN',
    name: 'Bronze',
    status: paymentStatus,
    paymentPeriod: 12,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paystackRef = queryParams.get('reference');
  const flutterRef = queryParams.get('tx_ref');
  const reference = paystackRef || flutterRef;

  const [failedBody, setFailedBody] = useState({
    header: 'Something went wrong',
    subText: 'We were not able to complete your payment. Please try again.',
  });
  const url = convertParamsToString(subRequest.VERIFY_PAYMENT, { reference });
  const onError = () => {
    setPaymentStatus('FAILED');
    setFailedBody({
      header: 'Something went wrong',
      subText: 'We were not able to complete your payment. Please try again.',
    });
    setIsLoading.off();
  };

  const onSuccess = (res: any) => {
    const data: paymentResType = res.data;
    const payStatus = data.status;
    switch (payStatus) {
      case 'SUCCESSFUL':
        setPaymentStatus('SUCCESSFUL');
        break;
      case 'FAILED':
        setPaymentStatus('FAILED');
        setFailedBody({
          header: 'Payment Failed',
          subText: 'An error occured in your payment. Please try again.',
        });
        break;
      default:
        setPaymentStatus('PENDING');
    }
    setSuccessRes(data);
    setIsLoading.off();
  };
  useQueryWrapper(['VERIFY_PAYMENT'], url, { onSuccess, onError });

  return (
    <Box bg="rgba(0, 0, 0, 0.6)" minH="100vh">
      <BaseModal isOpen={true} onClose={onClose}>
        {isLoading ? (
          <LoadingBody />
        ) : paymentStatus === 'SUCCESSFUL' ? (
          <SuccessBody info={successRes} />
        ) : paymentStatus === 'FAILED' ? (
          <FailedBody failBody={failedBody} />
        ) : (
          <PendingBody />
        )}
      </BaseModal>
    </Box>
  );
};

export default PaymentVerification;

type LoadingBodyProps = {
  subText?: string;
};
export const LoadingBody = (props: LoadingBodyProps) => {
  const { subText = ' Talking to HQ' } = props;
  return (
    <Box p="24px" textAlign="center">
      <Text fontWeight={500}>Processing</Text>
      <Text mt="1" mb="4" color="#666666">
        {subText}
      </Text>
      <Progress size="xs" isIndeterminate />
    </Box>
  );
};

type SuccessBodyProps = {
  info: paymentResType;
};
const SuccessBody = (props: SuccessBodyProps) => {
  const { info } = props;
  const { currency, amount, name, paymentPeriod } = info;
  const planName = toTitleCase(name);
  const [isLoading, setIsLoading] = useBoolean();

  return (
    <Box p="24px 31px" textAlign="center">
      <Text fontWeight={600} fontSize="18px">
        Subscription Successful
      </Text>
      <Image mt="4" mx="auto" objectFit="cover" src={successImg} alt="success Logo" />
      <Text mt="20px" mb="28px">
        Welcome to Oze {planName} Plan 🎉 Your {formatCurrency(amount, currency)} payment for{' '}
        {planName} ({paymentPeriod} month) was successfully processed. Please check your email for
        payment details.
      </Text>
      <Button
        isLoading={isLoading}
        w="100%"
        h="48px"
        bg="#1246EA"
        color="#fff"
        onClick={() => {
          setIsLoading.on();
          backToWebApp();
        }}
      >
        Back to Oze
      </Button>
    </Box>
  );
};

type FailedBodyProps = {
  failBody: {
    header: string;
    subText: string;
  };
};
const FailedBody = (props: FailedBodyProps) => {
  const { failBody } = props;
  const { header, subText } = failBody;
  return (
    <Box p="24px 31px" textAlign="center">
      <Image
        w="48px"
        h="48px"
        mt="4"
        mx="auto"
        objectFit="cover"
        src={failedImg}
        alt="success Logo"
      />
      <Text mt="4" fontWeight={600} fontSize="18px" color="#C71313">
        {header}
      </Text>

      <Text mt="4" mb="5">
        {subText}
      </Text>
      {/* <Button w="100%" h="48px" bg="#1246EA" color="#fff"
             onClick={() => backToApp()}

      >
        Try Again
      </Button> */}
      {/* <Button
        w="100%"
        mt="24px"
        h="48px"
        bg="#fff"
        color="#C71313"
        border="1px solid #C71313"
        onClick={() => backToApp()}

      >
        Cancel
      </Button> */}
    </Box>
  );
};

const PendingBody = () => {
  return (
    <Box p="24px 31px" textAlign="center">
      <Text fontWeight={600} fontSize="18px">
        Subscription Pending
      </Text>

      <Text mt="20px" mb="28px">
        We are processing your payment. We will send you an email once its done
      </Text>
      {/* <Button
        w="100%"
        h="48px"
        bg="#1246EA"
        color="#fff"
        onClick={backToApp}
      >
        Done
      </Button> */}
    </Box>
  );
};
