import { Heading, Text, Box, Flex, Image, Button, useBoolean, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import useGlobalStore from 'zStore';
import { format } from 'date-fns';
import { PUBLIC_PAGES } from 'routes/pagePath';
import { baseURL, headers, subRequest } from 'services';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import bronze from 'assets/plans/bronze.png';
import PromoCodeBox from 'components/Subscription/PromoCodeBox';
import DiscountSuccessModal from 'components/Subscription/DiscountSuccessModal';

const Order = () => {
  const navigate = useNavigate();
  const [selectedSub, discountCampaign] = useGlobalStore((state) => [state.subscription, state.discountCampaign]);
  // const urlParams = new URLSearchParams(window.location.search);
  // const currentSubEncoded = urlParams.get('currentSub');
  // const currentSubJSON = decodeURIComponent(currentSubEncoded || "");
  // const selectedSub = JSON.parse(currentSubJSON);
  // const selectedSub =JSON.parse(localStorage.getItem("selectedPlan")||"{}")
  const { date, amount, currency, image, id, businessId } = selectedSub;
  const formattedDate = format(new Date(date), 'EEEE d MMM yyyy');
  const [isPaymentEnabled, setIsPaymentEnabled] = useBoolean();
  const subPrice = formatCurrency(amount, currency);
  const { 
    onClose: closeDiscountSuccessModal, 
    isOpen: isDiscountSuccessModalOpen, 
    onOpen: openDiscountSuccessModal 
  } = useDisclosure();

  useEffect(() => {
    if (businessId === '12345678') {
      const location = localStorage.getItem('current-location') || '/';
      window.location.href = location;
      // navigate(-1)
    }
  }, [navigate, businessId]);

  const onSuccess = (data: any) => {
    const paymentLink = data.data?.paymentLink;
    // eslint-disable-next-line
    if (paymentLink == null || paymentLink == 'null') {
      if (discountCampaign?.code && discountCampaign.value === 100) {
        openDiscountSuccessModal();
        return;
      }
      toast.error('An error occured while initiating payment link. Please try again');
    } else {
      window.location.href = paymentLink;
    }
  };

  const onError = (error: any) => {
    const err = error as Record<any, any>;
    const message: any = err?.response?.data?.errors;
    toast.dismiss();
    if (Array.isArray(message)) {
      message.map((errorMsg) => toast.error(`${errorMsg ?? 'An error occured'}`));
    } else {
      toast.error(`${message ?? 'An error occured'}`);
    }
  };
  const [xReqKey, setxReqKey] = useState('');

  useEffect(() => {
    const updateXReqKey = () => {
      const newId = nanoid();
      setxReqKey(newId);
    };

    // Initialize xReqKey on component mount
    updateXReqKey();

    // Schedule the updateXReqKey function to run every 5 minutes
    const interval = setInterval(updateXReqKey, 5 * 60 * 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const postRequest = async ({ url, data }: any) => {
    const updatedHeader = {
      ...headers,
      'X-Request-Ref': xReqKey,
    };
    const response = await axios.post(url, data, { headers: updatedHeader });
    return response?.data || response;
  };

  const [autoRenew] = useBoolean(false);
  const { mutate, isLoading } = useMutation({
    mutationFn: postRequest,
    onSuccess,
    onError,
  });

  const getPaymentLink = async () => {
    const origin = window.location.origin;
    setIsPaymentEnabled.on();

    let payload = {
      businessId,
      subscriptionPlanId: id,
      recurrent: autoRenew,
      redirectUrl: `${origin}${PUBLIC_PAGES.PAYMENT_VERIFICATION}`,
    } as any;

    if (discountCampaign?.code) {
      payload = {
        ...payload,
        discountCode: discountCampaign.code,
      }
    }

    const data = JSON.stringify(payload);
    const url = `${baseURL}${discountCampaign?.code ? subRequest.INIT_DISCOUNT_PAYMENT : subRequest.INIT_PAYMENT}`;

    mutate({
      url,
      data,
    });
  };
  // const isBestValue =
  //   selectedSub.planName === "Gold" || selectedSub.planName === "Platinum";

  const calculateTotal = useMemo(() => {
    let total = amount;
    if (typeof discountCampaign?.discountAmount === 'number') {
      total = discountCampaign.discountAmount;
    }
    return formatCurrency(total, currency);
  }, [amount, currency, discountCampaign?.discountAmount])

  return (
    <Box p="24px" maxW="500px" mx="auto">
      <Heading fontSize="20px">Order Summary</Heading>
      <Text fontSize="14px">Please confirm and proceed to payments</Text>

      <Box
        p="16px"
        pl="12px"
        mt="32px"
        bg="#E7ECFD"
        border="1px solid #D0DAFB"
        borderRadius="8px"
        position="relative"
        overflow="hidden"
      >
        <Flex>
          <Image
            w={{ base: '34px', md: '64px' }}
            h={{ base: '34px', md: '64px' }}
            mr="10px"
            borderRadius="8px"
            objectFit="cover"
            src={image || bronze}
            alt="Plan Logo"
          />
          <Box>
            <Heading fontSize="16px" color="#161A5C">
              Oze {selectedSub.planName} - ({selectedSub.durationWord})
            </Heading>
            <Text mt="3px" w="90%" color="#45487D" fontSize="13px">
              You will be charged {formatCurrency(selectedSub.amount, selectedSub.currency)}/
              {selectedSub.duration} starting on
              {/* {new Date(date)} */} {formattedDate}
            </Text>
          </Box>
        </Flex>
        <Flex mt="25px" justifyContent="space-between">
          <Box />
          {/* <Box>
          Hide the Auto Renewal for now
            <FormControl display="flex" alignItems="center">
              <Switch id="email-alerts" size="sm"isChecked={autoRenew}  onChange={()=>{
                setIsPaymentEnabled.off();
                setAutoRenew.toggle()}} />
              <FormLabel
                htmlFor="email-alerts"
                ml="9px"
                mb="0"
                fontSize="13px"
                color="#45487D"
              >
                Auto Renewal
              </FormLabel>
            </FormControl>
          </Box> */}
          <Button
            variant="link"
            fontSize="13px"
            color="#1246EA"
            textDecoration="underline"
            onClick={() => navigate(-1)}
          >
            Change Plan
          </Button>
        </Flex>
      </Box>

      <PromoCodeBox subscriptionPlanId={id} />
      {/* {isBestValue ? (
        <Box my="34px">
          <Text
            color="#4D4D4D"
            mb="8px"
            fontSize={{ base: "14px", md: "16px" }}
          >
            Black Friday Promo Applied
          </Text>
          <Flex
            justifyContent="center"
            alignItems="center"
            fontWeight={700}
            bg="#000"
            color="#fff"
            textTransform="uppercase"
            p="12px"
            fontSize={{ base: "14px", md: "16px" }}
            border="2px solid red"
            borderRadius="8px"
          >
            You're SAVING 50% this Black Friday!
          </Flex>
        </Box>
      ) : null} */}
      <Flex
        mt="102px"
        background="#FBFCFE"
        padding="16px 12px"
        border="1px solid #E7ECFD"
        borderRadius="8px"
        gap="3"
        flexDir="column"
      >
        <Flex justifyContent="space-between">
          <Text fontSize="13px" color="#666666">
            Subscription Price
          </Text>
          <Text fontSize="13px" color="#666666">
            {subPrice}
          </Text>
        </Flex>
        {discountCampaign ? (
          <Flex justifyContent="space-between">
            <Text fontSize="13px" color="#666666">
              Discount
            </Text>
            <Text fontSize="13px" color="#666666">
              - {formatCurrency(discountCampaign.pricingValue || 0, currency)}
            </Text>
          </Flex>
        ) : null}
        <Flex justifyContent="space-between">
          <Text fontSize="14px" fontWeight={700}>
            Total
          </Text>
          <Text fontSize="14px" fontWeight={700}>
            {calculateTotal}
          </Text>
        </Flex>
      </Flex>
      <Button
        mt="24px"
        w="100%"
        background="#1246EA"
        borderRadius="8px"
        h="48px"
        color="#fff"
        isLoading={isLoading}
        onClick={getPaymentLink}
        isDisabled={isPaymentEnabled}
      >
        Proceed to Payment
      </Button>
      <Button variant="secondaryOutline" mt="10px" h="48px" w="100%" onClick={() => navigate(-1)}>
        Cancel
      </Button>
      <DiscountSuccessModal isOpen={isDiscountSuccessModalOpen} onClose={closeDiscountSuccessModal} />
    </Box>
  );
};

export default Order;

// const GoldBanner = () => {
//   return (
//     <Box
//       background="#000"
//       transform="rotate(45deg)"
//       color="#fff"
//       border="2px solid #FF0000"
//       fontWeight={600}
//       textAlign="center"
//       position="absolute"
//       top="22px"
//       right={{ base: "-79px", md: "-83px" }}
//       width={{ base: "67%", md: "50%" }}
//       fontSize={{ base: "12px", md: "14px" }}
//     >
//       BEST VALUE
//     </Box>
//   );
// };
