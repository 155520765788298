import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { EVENTS, Mixpanel } from "lib/mixpanel";
import { useEffect, useState } from "react";
import { baseURL, subRequest } from "services";
import { postRequest } from "services/api/apiHelper";
import { SubscriptionCampaignResponse } from "utils/types";
import useGlobalStore from "zStore";

interface PromoCodeErrorType {
  errors: string[];
  status: string;
}

export default function usePromoCode({ subscriptionPlanId }: { subscriptionPlanId: string }) {
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeError, setPromoCodeError] = useState("");
  const [successMessage, setSuccessMessage] = useState<number | null>(null);
  const [setDiscountCampaign] = useGlobalStore((state) => [state.setDiscountCampaign])

  const { mutate, isLoading: submittingCode } = useMutation({
    mutationFn: postRequest,
    onSuccess: ({ data }: SubscriptionCampaignResponse) => {
      setSuccessMessage(data?.value);
      setDiscountCampaign(data);
      Mixpanel.track(EVENTS.PROMO_DISCOUNT_SUCCESS_SEEN);
    },
    onError: (error: AxiosError<PromoCodeErrorType>) => {
      Mixpanel.track(EVENTS.PROMO_DISCOUNT_ERROR_SEEN);
      if (error.response?.data) {
        const errors: string[] = error.response?.data?.errors;
        setPromoCodeError(errors[0]);
      }
    },
  });
  
  useEffect(() => {
    return () => {
      setPromoCode('');
      setSuccessMessage(null);
      setDiscountCampaign(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const onSubmit = (promoCode: string) => {
    Mixpanel.track(EVENTS.PROMO_APPLY_BTN_CLICKED);
    if (!promoCode.length) {
      setPromoCodeError("Please enter a valid promo code");
      return;
    }
    mutate({ url: `${baseURL}${subRequest.VERIFY_PROMO_CODE}`, data: JSON.stringify({ code: promoCode, subscriptionPlanId }) })
  }
  
  return { onSubmit, promoCodeError, promoCode, setPromoCode, setPromoCodeError, submittingCode, successMessage, setSuccessMessage, setDiscountCampaign } as const;
}