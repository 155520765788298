import { Box, Flex } from "@chakra-ui/layout";
import { Heading, Button, Text, Image } from "@chakra-ui/react";
import { BsCheck2 } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import { backToWebApp, formatCurrency } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import useGlobalStore from "zStore";
import { PUBLIC_PAGES } from "routes/pagePath";
import { ENV } from "utils/constants";
import { EVENTS, Mixpanel } from "lib/mixpanel";
type pricing = {
  iosPlanId?: string;
  cost: string;
  save: string;
  ios: string;
  id: string;
  iosExternalPlanId?: string;
};

type AccessType = {
  text: string;
  isEnabled: boolean;
};

export type PlanType = {
  id: number;
  orderId: number;
  header: {
    logo: string;
    name: string;
    subText: string;
  };
  access: Array<AccessType>;
  price: {
    currency: string;
    duration: {
      ANNUAL: pricing;
      SEMI_ANNUAL: pricing;
      MONTH: pricing;
    };
  };
};

export type DurationValue = "ANNUAL" | "SEMI_ANNUAL" | "MONTH";
export type DeviceType = "ios" | "android";

export interface SubCardType {
  plan: PlanType;
  durationType: DurationValue;
  deviceType: DeviceType;
  businessId?: string | undefined | null;
  openGoldModal: () => void;
}

const SubCard = (props: SubCardType) => {
  const [setSub] = useGlobalStore((state) => [state.setSubscription]);
  const { plan, durationType, deviceType, businessId, openGoldModal } = props;
  const { header, access, price } = plan;
  const navigate = useNavigate();
  const isFreePlan = header.name === "Bronze";
  const isSilver = header.name === "Silver";
  const isBestValue = header.name === "Gold";
  const isIos = deviceType === "ios";
  const planPrice = isIos
    ? price.duration[durationType].ios
    : price.duration[durationType].cost;
  const planSaving = price.duration[durationType].save;
  const planId = price.duration[durationType].id;
  const iosPlanId = price.duration[durationType].iosPlanId;
  let iosExternalPlanId = price.duration[durationType].iosExternalPlanId || "";
  const { duration, durationWord, subText } =
    getSubscriptionDetails(durationType);

  const handleSelection = () => {
    if (isSilver) {
      openGoldModal();
      Mixpanel.track(EVENTS.PROMPT_VIEWED);
      return;
    }
    if (isFreePlan) {
      let backToApp = PUBLIC_PAGES.ANDROID_HOME;
      if (deviceType === "ios") {
        backToApp = PUBLIC_PAGES.IOS_HOME;
        return (window.location.href = backToApp);
      }
      backToWebApp();
      return;
    }
    if (deviceType === "ios") {
      if (process.env.REACT_APP_CLIENT_ENV_QA === ENV.DEV) {
        // Check if the value is a production value
        if (iosExternalPlanId.includes(".OZE.")) {
          iosExternalPlanId = iosExternalPlanId.replace(
            ".OZE.",
            ".testflightqa."
          );
        }
      }
      const url = `/ios?planId=${iosPlanId}&externalPlanId=${iosExternalPlanId}`;
      const iosPage = url;
      return (window.location.href = iosPage);
    }

    const currentSub = {
      planName: plan.header.name,
      image: plan.header.logo,
      duration,
      durationWord,
      date: new Date(),
      currency: plan.price.currency,
      amount: planPrice,
      id: planId,
      businessId,
    };
    setSub({
      ...currentSub,
    });
    const location = window.location.href;
    localStorage.setItem("current-location", location);
    navigate("/order");
  };
  return (
    <Box
      bg="#F7F7F7"
      mt="8"
      mx="23px"
      border="1px solid #EEEEEE"
      boxShadow="0px 4px 32px rgba(0, 0, 0, 0.08)"
      borderRadius="16px"
      p="24px"
      overflow="hidden"
    >
      <Flex justifyContent="space-between" position="relative">
        <Flex>
          <Image
            w="44px"
            h="44px"
            mr="13px"
            objectFit="cover"
            src={header.logo}
            alt="Plan preview"
          />
          <Box>
            <Heading color="black" fontSize="24px">
              {header.name}
            </Heading>

            <Text color="black" fontSize="12px">
              {header.subText}
            </Text>
          </Box>
        </Flex>
        {isBestValue ? <GoldBanner /> : null}
      </Flex>
      <Box mt="32px">
        {access.map((item, index) => (
          <Flex mt="8px" key={index}>
            {item.isEnabled ? (
              <BsCheck2
                color="#1246EA"
                size="13px"
                style={{ marginTop: "2px" }}
              />
            ) : (
              <MdClear
                color="#8B8CAD"
                size="13px"
                style={{ marginTop: "2px" }}
              />
            )}
            <Text
              ml="6px"
              color={item.isEnabled ? "#0A0C29" : "#73769D"}
              fontSize="12px"
            >
              {item.text}
            </Text>
          </Flex>
        ))}
      </Box>

      <Box mt="24px">
        <Flex color="black" alignItems="center">
          {isFreePlan ? (
            <Heading fontSize="24px">Free</Heading>
          ) : (
            <Heading fontSize="24px">
              {formatCurrency(+planPrice, price.currency)}
            </Heading>
          )}

          <Text mt="4px" ml="8px">
            / {isFreePlan ? "forever" : duration}
          </Text>
        </Flex>
        {isFreePlan ? (
          <Text color="black" fontSize="12px" fontWeight={500}>
            You save and do more with our paid plans
          </Text>
        ) : (
          <Flex mt="5px">
            <Text color="#1246EA" fontSize="12px" fontWeight={700}>
              Save {planSaving}
            </Text>

            <Text color="black" ml="5px" fontSize="12px" fontWeight={500}>
              {subText}
            </Text>
          </Flex>
        )}
        <Button w="100%" mt="32px" bg="blue" h="48px" onClick={handleSelection}>
          {`Get ${header.name} Plan`}
        </Button>
      </Box>
    </Box>
  );
};

export default SubCard;

// const GoldBannerBlack = () => {
//   return (
//     <Box
//       background="#000"
//       transform="rotate(45deg)"
//       color="#fff"
//       fontWeight={600}
//       textAlign="center"
//       position="absolute"
//       border="2px solid #FF0000"
//       top="0px"
//       right={{ base: "-67px", md: "-83px" }}
//       width={{ base: "67%", md: "50%" }}
//       fontSize={{ base: "14px", md: "16px" }}
//     >
//       50% OFF <br></br> BLACK FRIDAY
//     </Box>
//   );
// };
export const GoldBanner = () => {
  return (
    <Box
      background="#1246EA"
      transform="rotate(45deg)"
      color="#fff"
      fontWeight={600}
      textAlign="center"
      position="absolute"
      top="11px"
      right={{ base: "-67px", md: "-83px" }}
      width={{ base: "67%", md: "50%" }}
      fontSize={{ base: "14px", md: "16px" }}
    >
      BEST VALUE
    </Box>
  );
};

export const getSubscriptionDetails = (durationType: string) => {
  let duration, durationWord, subText;
  switch (durationType) {
    case "ANNUAL":
      duration = "year";
      durationWord = "Yearly";
      subText = "when you pay annually";
      break;
    case "SEMI_ANNUAL":
      duration = "6 Months";
      durationWord = "6 Months";
      subText = "when you pay every 6-months";
      break;
    default:
      duration = "month";
      durationWord = "Monthly";
      subText = "when you pay every 6-months or annually";
  }

  return { duration, durationWord, subText };
};
