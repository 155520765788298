import { PlanType } from "./SubCard";
import bronzeImg from "assets/plans/bronze.png";
import silverImg from "assets/plans/silver.png";
import goldImg from "assets/plans/gold.png";
import platinumImg from "assets/plans/platinum.png";

export const bronze: PlanType = {
  id: 1,
  orderId: 1,
  header: {
    logo: bronzeImg,
    name: "Bronze",
    subText: "Just getting started",
  },
  access: [
    {
      text: "Access a dashboard of metrics and charts",
      isEnabled: true,
    },
    {
      text: "Record up to 20 sales and expenses monthly",
      isEnabled: true,
    },
    {
      text: "Send up to 10 invoices and receipts monthly",
      isEnabled: true,
    },
    {
      text: "Total of 10 products in inventory monthly",
      isEnabled: true,
    },
    {
      text: "Sell up to 10 products on Oze shop",
      isEnabled: true,
    },
    {
      text: "Access collateral-free loan",
      isEnabled: false,
    },
    {
      text: "Receive and send payment reminders",
      isEnabled: false,
    },
    {
      text: "Manage employee permissions",
      isEnabled: false,
    },
    {
      text: "Add multiple users to your account",
      isEnabled: false,
    }
    // {
    //   text: "Access on a laptop",
    //   isEnabled: false,
    // },
  ],
  price: {
    currency: "USD",
    duration: {
      ANNUAL: {
        cost: "0",
        save: "0",
        ios: "0",        
        id: "0",
      },
      SEMI_ANNUAL: {
        cost: "0",
        save: "0",
        ios: "0",        
        id: "0",
      },
      MONTH: {
        cost: "0",
        save: "0",
        ios: "0",        
        id: "0",
      },
    },
  },
};
export const silver: PlanType = {
  id: 2,
  orderId: 2,
  header: {
    logo: silverImg,
    name: "Silver",
    subText: "Manage your business",
  },
  access: [
    {
      text: "Access a dashboard of metrics and charts",
      isEnabled: true,
    },
    {
      text: "Record unlimited sales and expenses monthly",
      isEnabled: true,
    },
    {
      text: "Send up to 25 invoices and receipts monthly",
      isEnabled: true,
    },
    {
      text: "Total of 25 products in inventory monthly",
      isEnabled: true,
    },
    {
      text: "Sell up to 25 products on Oze shop",
      isEnabled: true,
    },
    {
      text: "Access collateral-free loan",
      isEnabled: true,
    },
    {
      text: "Receive and send payment reminders",
      isEnabled: true,
    },
    {
      text: "Manage employee permissions",
      isEnabled: false,
    },
    {
      text: "Add multiple users to your account",
      isEnabled: false,
    }
    // {
    //   text: "Access on a laptop",
    //   isEnabled: false,
    // },
  ],
  price: {
    currency: "$",
    duration: {
      ANNUAL: {
        cost: "35.99",
        save: "23.31%",
                ios: "35.99",
                id: "35.99",
      },
      SEMI_ANNUAL: {
        cost: "19.99",
        save: "16.67%",
                ios: "35.99",
                id: "35.99",
      },
      MONTH: {
        cost: "3.99",
        save: "more",
                ios: "35.99",
                id: "35.99",
      },
    },
  },
};
export const gold: PlanType = {
  id: 3,
  orderId: 3,
  header: {
    logo: goldImg,
    name: "Gold",
    subText: "Grow your business",
  },
  access: [
    {
      text: "Access a dashboard of metrics and charts",
      isEnabled: true,
    },
    {
      text: "Record unlimited sales and expenses",
      isEnabled: true,
    },
    {
      text: "Send unlimited invoices and receipts",
      isEnabled: true,
    },
    {
      text: "Unlimited number of product inventory",
      isEnabled: true,
    },
    {
      text: "Sell unlimited products on Oze shop",
      isEnabled: true,
    },
    {
      text: "Access collateral-free loan",
      isEnabled: true,
    },
    {
      text: "Receive and send payment reminders",
      isEnabled: true,
    },
    {
      text: "Manage employee permissions",
      isEnabled: true,
    },
    {
      text: "Add up to 3 users to your account",
      isEnabled: true,
    },
    // {
    //   text: "Access on a laptop",
    //   isEnabled: true,
    // },
  ],
  price: {
    currency: "$",
    duration: {
      ANNUAL: {
        cost: "89.99",
        save: "23.31%",
                ios: "35.99",
                id: "35.99",
      },
      SEMI_ANNUAL: {
        cost: "49.99",
        save: "16.67%",
                ios: "35.99",
                id: "35.99",
      },
      MONTH: {
        cost: "9.99",
        save: "more",
                ios: "35.99",
                id: "35.99",
      },
    },
  },
};
export const platinum: PlanType = {
  id: 4,
  orderId: 4,
  header: {
    logo: platinumImg,
    name: "Platinum",
    subText: "Grow your business",
  },
  access: [
    {
      text: "Access a dashboard of metrics and charts",
      isEnabled: true,
    },
    {
      text: "Record unlimited sales and expenses",
      isEnabled: true,
    },
    {
      text: "Send unlimited invoices and receipts",
      isEnabled: true,
    },
    {
      text: "Unlimited number of product inventory",
      isEnabled: true,
    },
    {
      text: "Sell unlimited products on Oze shop",
      isEnabled: true,
    },
    {
      text: "Access collateral-free loan",
      isEnabled: true,
    },
    {
      text: "Receive and send payment reminders",
      isEnabled: true,
    },
    {
      text: "Manage employee permissions",
      isEnabled: true,
    },
    {
      text: "Add unlimited users to your account",
      isEnabled: true,
    },
    // {
    //   text: "Access on a laptop",
    //   isEnabled: true,
    // },
  ],
  price: {
    currency: "$",
    duration: {
      ANNUAL: {
        cost: "299.99",
        save: "23.31%",
                ios: "35.99",
                id: "35.99",
      },
      SEMI_ANNUAL: {
        cost: "124.99",
        save: "16.67%",
                ios: "35.99",
                id: "35.99",
      },
      MONTH: {
        cost: "24.99",
        save: "more",
                ios: "35.99",
                id: "35.99",
      },
    },
  },
};


